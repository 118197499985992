import React from 'react'
import classNames from 'classnames'
import { getCurrentLang } from '../../../utils/current-language-service'
import { getConfigByLanguage } from '../../../utils/common-utils'
import componentStyles from './CommonWatchMore.module.scss'

const CommonWatchMore = ({ children, customClasses }) => {
  const language = getCurrentLang()
  const config = getConfigByLanguage(language)
  const { addTitle } = config.plainTextPage

  const containerClasses = classNames(
    componentStyles.container,
    customClasses && customClasses.containerClass)
  const titleClasses = classNames(
    componentStyles.title,
    customClasses && customClasses.titleClass)


  return(
    <section className={containerClasses}>
      <h2 className={titleClasses}>{addTitle}</h2>
      {children}
    </section>
  )
}

export default CommonWatchMore
