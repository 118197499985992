import React from 'react'
import { graphql } from 'gatsby'
import { getCurrentLangKey } from 'ptz-i18n'
import { useSiteMetadata } from '../../hooks/use-site-metadata'
import Layout from '../../components/Layout/Layout'
import ProductList from '../../components/ProductList/ProductList'
import layoutStyles from '../../components/Layout/Layout.module.scss'
import componentStyles from './plain-text-page.module.scss'
import CommonWatchMore from '../../parts/common/CommonWatchMore/CommonWatchMore'

const PlainTextPage = ({ data, location }) => {
  const page = data.markdownRemark
  const url = location.pathname
  const { langs, defaultLangKey } = useSiteMetadata().languages
  const language = getCurrentLangKey(langs, defaultLangKey, url)
  const { description, keywords, title } = page.frontmatter

  return (
    <Layout language={language} location={location} seo={{
      description,
      keywords,
      title
    }}>
      <div className={layoutStyles.container}>
        <div className={componentStyles.textPageContent}>
          <h1>{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: page.html }}/>
        </div>
        <CommonWatchMore>
          <ProductList customClasses={{
            productListClass: componentStyles.productList
          }}/>
        </CommonWatchMore>
      </div>
    </Layout>
  )
}

export default PlainTextPage

export const pageQuery = graphql`
  query PageBySlug($filePath: String!) {
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      id
      html
      frontmatter {
        title
        description
      }
    }
  }
`
